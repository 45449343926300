// Welcome.js

import React from "react";

const Welcome = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full">
      {/* Nouvelle image ajoutée ici */}
      {/* <img
                src='https://ui-chatbot1.s3.eu-north-1.amazonaws.com/Programme_2024.png'
                alt='Programme 2024'
                className='w-22 mb-5' // Ajoutez des classes pour ajuster la taille et la marge selon besoin
            /> */}
      <img
        src="https://ui-chatbot1.s3.eu-north-1.amazonaws.com/mr+logo+(3).png"
        alt="Welcome"
        className="w-26.25 h-16 mb-5"
      />
      <div className="text-left">
        <h1 className="text-xl font-bold mb-2 ml-5 mr-5">
          Bonjour, je suis Victor, l'IA du Mouvement Réformateur !
        </h1>
        <p className="text-l ml-5 mr-5">
          Posez-moi toutes vos questions sur le programme et la vision du MR.
        </p>
        <p className="text-m ml-5 mr-5">Avec le MR, l'avenir s'éclaire !</p>
      </div>
    </div>
  );
};

export default Welcome;
// Welcome.js
// Welcome.js
